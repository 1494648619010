import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import SectionIntro from '../SectionIntro';
import isMobile from '../../utils/isMobile';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ReactMarkdown from 'react-markdown';
import { inViewTriggerThresholds } from '../../animationGlobals';

export const FullWidthCard = (props) => {
  const {
    title = '',
    subtitle = '',
    imageData,
    description = '',
    eyebrow = '',
    heading = '',
    lineBreak = false,
    subHeading = '',
    setImageToLeft = true,
  } = props;

  const sectionRef = useRef();
  const cardRef = useRef();
  const isMobileLayout = isMobile();
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    const cardEl = cardRef.current;
    const toCommon = {
      duration: 0.5,
      ease: 'power4.out',
    };
    const delayCommon = `<${toCommon.duration * 0.25}`;
    const InFromRight = [
      {
        opacity: 0,
        x: '25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const InFromLeft = [
      {
        opacity: 0,
        x: '-25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const InFromBottom = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const animation = gsap
        .timeline({ paused: true })
        .fromTo(cardEl, ...InFromBottom)

    setAnimation(animation);

    return () => {
      animation.progress(1);
      animation.kill();
    };
  }, [isMobileLayout]);

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      onEnter: () => {
        if (animation) {
          animation.play();
        }
      },
    });

    return () => {
      trigger.kill();
    };
  }, [animation]);

  return (
      <div ref={sectionRef} className="full-width-card">
        {heading && <SectionIntro title={heading} subtitle={subHeading}/>}
        <div className="full-width-card__inner">
          <div ref={cardRef} className="full-width-card__body">
            <div className="full-width-card__image">
              {imageData && (
                  <Image fluid={imageData?.fluid}/>
              )}
            </div>
            <div className="full-width-card__content">
              {subtitle && (
                  <p className="full-width-card__subtitle">
                    {subtitle}
                  </p>
              )}
              <h3 className="full-width-card__title">
                {title}
              </h3>
              <ReactMarkdown skipHtml={false} unwrapDisallowed>{description}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
  );
};
