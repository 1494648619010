import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import SectionIntro from '../SectionIntro';
import isMobile from '../../utils/isMobile';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const SingleCard = (props) => {
  const { card } = props;
  const imageFluid = card?.imageData?.fluid;

  return (
      <div className="card">
        <div className="card__image">
          <Image fluid={imageFluid}/>
        </div>
        <div className="card__content">
          <h3 className="card__title">{card?.title}</h3>
          <p className="card__description">{card?.description}</p>
        </div>
      </div>
  );
};

export const TwoColumnCard = (props) => {
  const {
    cardOne,
    cardTwo,
    heading,
    subHeading,
  } = props;

  const sectionRef = useRef();
  const cardOneRef = useRef();
  const cardTwoRef = useRef();
  const isMobileLayout = isMobile();
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    const cardOneEl = cardOneRef.current;
    const cardTwoEl = cardTwoRef.current;
    const toCommon = {
      duration: 0.5,
      ease: 'power4.out',
    };
    const delayCommon = `<${toCommon.duration * 0.25}`;
    const InFromRight = [
      {
        opacity: 0,
        x: '25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const InFromLeft = [
      {
        opacity: 0,
        x: '-25%',
      },
      {
        opacity: 1,
        x: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const InFromBottom = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];
    const cardOneEntranceDirection = isMobileLayout ? InFromBottom : InFromLeft;
    const cardTwoEntranceDirection = isMobileLayout ? InFromBottom : InFromRight;
    const animation = gsap
        .timeline({ paused: true })
        .fromTo(cardOneEl, ...cardOneEntranceDirection)
        .fromTo(cardTwoEl, ...cardTwoEntranceDirection)

    setAnimation(animation);

    return () => {
      animation.progress(1);
      animation.kill();
    };
  }, [isMobileLayout]);

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      onEnter: () => {
        if (animation) {
          animation.play();
        }
      },
    });

    return () => {
      trigger.kill();
    };
  }, [animation]);

  return (
      <div ref={sectionRef} className="two-column-card">
        {heading && <SectionIntro title={heading} subtitle={subHeading}/>}
        <div
            className="two-column-card__inner"
        >
          <div className="two-column-card__cards">
            <div ref={cardOneRef} className="two-column-card__card two-column-card__card--1">
              {cardOne && <SingleCard card={cardOne}/>}
            </div>
            <div ref={cardTwoRef} className="two-column-card__card two-column-card__card--2">
              {cardTwo && <SingleCard card={cardTwo}/>}
            </div>
          </div>
        </div>
      </div>
  );
};
