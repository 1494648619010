import React from 'react';
import { FullWidthCard } from '../FullWidthCard/FullWidthCard';
import { TwoColumnCard } from '../TwoColumnCard/TwoColumnCard';

const BenefitsSection = (benefits) => {
  const items = Object.entries(benefits).map(([ key, benefit ]) => {
    const {
      __typename,
      eyebrow,
      fullWidthCardDescription,
      card,
      image,
      titleBlack,
      title,
      subTitle,
      setImageToLeft,
    } = benefit;

    switch (__typename) {
      case 'ContentfulComponentFullWidthCardWithImage':
        return (
            <FullWidthCard
                title={title}
                subtitle={eyebrow}
                description={fullWidthCardDescription?.description}
                imageData={image}
                heading={titleBlack}
                subHeading={subTitle}
                setImageToLeft={setImageToLeft}
            />
        );
      case 'ContentfulComponentTwoColumnCard':
        return (
            <TwoColumnCard
                cardOne={{
                  title: card?.[0]?.title,
                  description: card?.[0]?.description?.description,
                  imageData: card?.[0]?.image || null,
                }}
                cardTwo={{
                  title: card?.[1]?.title,
                  description: card?.[1]?.description?.description,
                  imageData: card?.[1]?.image || null,
                }}
                heading={titleBlack}
                subHeading={subTitle}
            />
        );
      default:
        return null;
    }
  });

  return (
      <section className="benefits-section">
        <div className="benefits-section__inner">{items}</div>
      </section>
  );
};

export default BenefitsSection;
